import { Injectable } from '@angular/core';
import {
  AcceptFreightAssignationFromMarketCommand,
  AcceptFreightAssignationFromMarketCommandHandler,
} from '@okcargo/command-processor';
import { BaseCommandRepository } from 'src/app/common/infrastructure/command.repository';

@Injectable({ providedIn: 'root' })
export class MarketCommandService {
  private readonly acceptFreightAssignationFromMarketCommandHandler: AcceptFreightAssignationFromMarketCommandHandler;

  constructor(private readonly commandRepository: BaseCommandRepository) {
    this.acceptFreightAssignationFromMarketCommandHandler = new AcceptFreightAssignationFromMarketCommandHandler(
      commandRepository,
    );
  }

  public acceptFreightAssignationFromMarket(command: AcceptFreightAssignationFromMarketCommand): Promise<void> {
    return this.acceptFreightAssignationFromMarketCommandHandler.handle(command);
  }
}
